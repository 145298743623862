html,
body {
    height: 100%;
    margin: 0;
    font-size: 14px;

    overflow: overlay;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: #282c34;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;

    background: hsl(0deg, 0%, 46%, 0.15);
    padding: 1px 5px 2px 5px;
    border-radius: 3px;

    min-height: 1em;
}

a {
    color: #61dafb;
}


.app {
    display: flex;
    flex-direction: column;

    height: 100%;

    color: hsl(0deg, 0%, 80%);

    header {
        display: flex;
        padding: 0 2em;
        align-items: center;

        background: hsl(267deg, 100%, 24%);
        box-shadow: 0 0 8px 0px #0000008a;

        color: hsl(0deg, 0%, 90%);
        font-size: 1em;
    }

    main {
        flex: 1 1 auto;
        padding: 2em 3em;
        min-height: 200px;

        // background: hsla(0deg, 0%, 100%, 0.02);
    }

    footer {
        padding: 1em 2em;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        font-size: small;
        color: hsl(0deg, 0%, 40%);

        .envVars {
            grid-row: span 2;

            display: grid;
            grid-template-rows: auto auto;
            grid-auto-flow: column;
            align-items: center;
            column-gap: 20px;


            span {
                font-size: .8em;
                user-select: none;
            }

            code {
                color: hsl(0deg, 0%, 60%);
            }

        }
    }
}

input {
    font-family: monospace;
    font-size: 16px;
    padding: 3px 8px;
}